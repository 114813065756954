.table-wrapper {
  width: 100%;
}

.table {
  margin-left: auto;
  margin-right: auto;
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 8px 8px #ccc;
  border-radius: 1rem;
  white-space: nowrap;
  width: fit-content;
  max-width: 80%;
  table-layout: auto;
  overflow-x: auto;
}

.table th,
.table td {
  width: 10vh;
  padding: 1rem;
  border-top: 0.5px solid #0000001c;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label {
  border-radius: 0.3rem;
  padding: 0.3rem;
  color: white;
}

.label-true {
  background-color: #42a942;
}

.label-false {
  background-color: #d9534f;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.actions svg {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    scale: 1.2;
    filter: brightness(1.5);
  }
}

.delete-btn {
  color: #e10c05b2;
}

.modal-container {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0000006e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modals {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  height: fit-content;
  width: fit-content;
  box-shadow: 0px 10px 10px #00000025;
}

.modal-delete {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  height: fit-content;
  min-width: 24rem;
  max-width: fit-content;
  box-shadow: 0px 2px 10px #00000025;
}

.modal-delete-text {
  border-radius: 0.3rem;
  color: #e52f2f;
  border-color: #e52f2f;
  background-color: #f8d7da;
  text-align: center;
  padding: 0% 2%;
  font-weight: 600;
}

.btn-close-modal {
  padding: 0.5rem 0.5rem;
  border-radius: 0.4rem;
  border: none;
  background-color: #e52f2f;
  font-weight: 600;
  color: #f5cfcf;
  transition: all 0.1s ease-in-out;
  &:hover {
    transition: all 0.1s ease-in-out;
    background: #c42323;
    color: #ffffff;
  }
  &:focus {
    transition: all 0.1s ease-in-out;
    background: #a71c1c;
    color: #ffffff;
  }
}

.btn-submit {
  padding: 0.5rem 0.5rem;
  border-radius: 0.4rem;
  border: none;
  background-color: #3c3c7c;
  font-weight: 600;
  color: #d1d1e4;
  transition: all 0.1s ease-in-out;
  &:disabled {
    background: #2f2f72;
  }
  &:hover {
    transition: all 0.1s ease-in-out;
    background: #2f2f72;
    color: #ffffff;
  }
  &:focus {
    transition: all 0.1s ease-in-out;
    background: rgb(44, 44, 105);
    color: #ffffff;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  border: 1px solid rgb(184, 184, 184);
  border-radius: 0.4rem;
  padding: 0.3rem;
  font-size: 1rem;
}

.form-group label {
  margin-bottom: 0.2rem;
}

.errorAlert {
  color: #e52f2f;
  border-color: #e52f2f;
  background-color: #f8d7da;
  text-align: center;
  padding: 0% 2%;
  font-weight: 600;
}

.row-wrapper {
  display: flex;
  flex-direction: column;
  width: max-content;
}

.btn-buy {
  margin-left: 25%;
  border-radius: 0.4rem;
  border: none;
  background-color: #3ebefa;
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  transition: all 0.1s ease-in-out;
  &:hover {
    transition: all 0.1s ease-in-out;
    background: #30aae2;
    color: #ffffff;
  }
  &:focus {
    transition: all 0.1s ease-in-out;
    background: #2798cc;
    color: #ffffff;
  }
}
.status-style-actif {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  border-radius: 1rem;
  background-color: rgba(67, 173, 63, 0.26);
}
.status-style-inactif {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  border-radius: 1rem;
  background-color: rgba(156, 83, 83, 0.26);
}
.id-style {
  font-weight: 500;
  text-align: center;
  border-radius: 1rem;
  background-color: rgba(83, 121, 156, 0.144);
}
.category-style {
  font-weight: 500;
  text-align: center;
  border-radius: 1rem;
  background-color: rgba(0, 132, 255, 0.164);
}

.image-style {
  padding: 0.1rem 1rem;
}

.description-style {
  padding: 0.5rem;
  font-weight: 500;
  text-align: center;
  border-radius: 1rem;
  background-color: #bddfff1a;
}

.filter-style {
  display: flex;
}
.div-style {
  width: 180vh;
}
.spinner-Style {
  padding: 5vh;
  display: flex;
  justify-content: center;
}

.spinner-Style-Details {
  padding: 5vh;
  width: 25vh;
  display: flex;
  justify-content: center;
}

.btn-select {
  border: 0px;
}
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.modal-containerP {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0000006e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalsP {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  height: fit-content;
  width: fit-content;
  box-shadow: 0px 10px 10px #696969;
}

.modal-deleteP {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 2rem;
  background-color: rgb(255, 255, 255);
  height: fit-content;
  width: 22em;
  box-shadow: 0px 2px 10px #696969;
}

.modal-delete-text {
  color: #e52f2f;
  border-color: #e52f2f;
  background-color: #f8d7da;
  text-align: center;
  padding: 0% 2%;
  font-weight: 600;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  border: 1px solid rgb(184, 184, 184);
  border-radius: 0.4rem;
  padding: 0.3rem;
  font-size: 1rem;
  border: 1px solid #555;
}

.form-group label {
  margin-bottom: 0.2rem;
}

.errorAlert {
  color: #e52f2f;
  border-color: #e52f2f;
  background-color: #f8d7da;
  text-align: center;
  padding: 0% 2%;
  font-weight: 600;
}

.previewImage {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  background-color: rgb(255, 255, 255);
}

.image-Information {
  width: 18rem;
  font-weight: 750;
  font-size: 15px;
}

.image-Option {
  font-weight: 500;
  font-size: 16px;
}

/* .homenav{

    display: flex;
    flex-direction: row;
   width: 100px;
   height: 100px;
}
.homeimg
{
  width: 100px;
  height: 100px;
} */
.AdminPoducts-page {
  height: 100vh;
  width: 100vh;
}
.spinner-Table-Style {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.btn-login {
  height: min-content;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  color: black;
  background-color: transparent;
  transition: all 0.25s ease-in-out;
  border-radius: 0.4rem;
  &:hover {
    color: black;
    background-color: rgba(87, 87, 87, 0.13);
  }
}
.alert-display {
  width: fit-content;
  height: fit-content;
}

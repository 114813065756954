.Login {
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: end;
}
.accordion {
  width: 300px;
  height: 100px;
}
.accordionbody {
  background-color: yellow;
  width: 60%;
}
.logout {
  display: flex;
  justify-content: center;
}
.dropdowntoggle {
  display: flex;
  width: 180px;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  color: black;
  border: none;
  &:hover {
    background-color: #e2e3e4;
    color: black;
  }
}

.imageprofil {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 0.2rem;
}
.username {
  font-size: 10px;
  margin-left: 10px;
  font-weight: 500;
}
.dropdown {
  margin-left: 70px;
}
.username {
  font-size: 14px;
}
.dropdownmenu {
  width: 180px;
}
.navbaradmin {
  display: flex;
  flex-direction: row;
}
.navbaradmincol {
  display: flex;
  flex-direction: column;
  background-color: yellow;
}
.navbarcolumn {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-color: #323232;
  color: #f9f3f7;
}
.navbarcolumn2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #323232;
  color: #f9f3f7;
}
.textnavbar {
  display: flex;
  text-align: start;
  border: none;
  width: 15rem;
  background-color: transparent;
  background-size: 100vh 100vh;
  color: #f9f3f7;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50vh;
  transition: all 0.1s ease-in-out;
  &:hover {
    background-color: #424242;
    color: #f9f3f7;
  }
  &:active {
    background-color: #424242;
  }
}
.stucktwoimages {
  display: flex;
  flex-direction: row;
}

.dropdown-products {
  border: none;
  background-color: #323232;
}
.error {
  color: red;
  font-size: 13px;
}

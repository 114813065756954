.carousel-style {
  height: min-content;
  width: min-content;
  border: none;
}
.carousel-item-next {
  transition: transform 0s ease-in-out;
}

.carousel-item-prev {
  transition: transform 0s ease-in-out;
}
.carousel-item-left {
  transform: translateX(-100%);
}
.carousel-item-right {
  transform: translateX(100%);
}

.image-adjust {
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #faf9f6;
}
.App-logo {
  height: 70px;
  width: 150px;
  pointer-events: none;
  display: flex;
  justify-content: left;
  margin-top: 100px;
  margin-left: 100px;
  margin-bottom: 10px;
}

.image {
  width: 148px;
  height: 63px;
  margin-top: 50px;
  margin-left: 470px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Nav-bar {
  display: flex;
  justify-content: left;
  flex-direction: row;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.carouselhomepage {
  margin-top: 10px;
  width: 660px;
  height: 274px;
  /* width: 660px;
 height: 274px;  */
}
.carousel {
  padding: 3px;
  border-width: 2px;
  border-color: black;
  border-style: solid;
  /* width: 650px;
  height: 254px; */
}
.homepage {
  flex-direction: column;
  display: flex;
  height: 100%;
  align-items: center;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: #797b79;
}
body {
  color: #333;
}
p {
  font-size: 90.5%;
}
.cardAC {
  width: 230px;
  margin: 5px;
}
.cardAM2 {
  width: 230px;
  margin: 5px;
  font-size: 13px;
}
.cardSA3 {
  width: 230px;
  margin: 5px;
  font-size: 15.4475px;
}
.cardnema4 {
  width: 147.8px;
  margin: 5px;
  font-size: 11px;
}
.cardstepmotor5 {
  width: 230px;
  margin: 5px;
  font-size: 14.247px;
}
.card_row1 {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  width: 50%;
  height: 500px;
}
.card_row2 {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  width: 50%;
  height: 500px;
}
.card_row3 {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  width: 50%;
  height: 500px;
}
.cardgearmotor6 {
  width: 230px;
  margin: 5px;
  font-size: 17.22px;
}
.carddcplanet7 {
  width: 230px;
  margin: 5px;
  font-size: 15px;
}
.cardmicrogearmotor8 {
  width: 230px;
  margin: 5px;
  font-size: 21.657px;
}
.cardbldc9 {
  width: 147.8px;
  margin: 5px;
  font-size: 14.5px;
}
.cardbldcgear10 {
  width: 147.8px;
  margin: 5px;
  font-size: 13.9px;
}
.cardbldcplanetgear11 {
  width: 147.8px;
  margin: 5px;
  font-size: 14px;
}
.cardcoreless12 {
  width: 147.8px;
  margin: 5px;
  font-size: 14px;
}
.cardPlanetaryGearBox13 {
  width: 147.8px;
  margin: 5px;
  font-size: 20px;
}
.cardshadedpole14 {
  width: 147.8px;
  margin: 5px;
  font-size: 12.5px;
}
.cardcontroller15 {
  width: 147.8px;
  margin: 5px;
  font-size: 34px;
}
.homepageofhome {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.mb-3 {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.videoabout {
  display: flex;
}
img.hover-zoom {
  transform: scale(0.8);
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
}
.cuscul {
  margin-top: -17px;
  margin-bottom: -10px;
  margin-left: 0px;
  margin-right: 10px;
  color: #717171;
  font-weight: 500;
}
.cusculsansmargin {
  color: #717171;
  font-weight: 500;
}
.textrow1 {
  display: flex;
  flex-direction: row;
}
.columntext {
  display: flex;
  flex-direction: column;
}
/* ABOUT US */
.text-font1 {
  display: flex;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

.text-font2 {
  display: flex;
  text-align: center;
  font-weight: bold;
  font-size: 2.5rem;
}
.homecenter {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}
